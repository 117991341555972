import * as React from 'react';
import { useState } from 'react';
import {
  Button,
  DateField,
  EditButton,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  useRedirect,
  WrapperField,
} from 'react-admin';
import { ButtonWithDisabledTooltip } from 'src/components/ButtonWithDisabledTooltip';
import DatetimeField from 'src/components/DatetimeField';
import TranslatedField from 'src/components/TranslatedField';
import UpdateConfirm from 'src/components/UpdateConfirm';
import { workStatusExcludingReviewed } from './field';

const BookTitle = () => {
  const record = useRecordContext();
  // ロード中は空
  if (!record) return null;
  return <span>{record.name}</span>;
};

export const BookShow: React.FC = () => {
  const [isTreatDialogOpen, setIsTreatDialogOpen] = useState(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const redirect = useRedirect();
  const ListActions: React.FC = () => {
    const record = useRecordContext();
    return (
      <TopToolbar>
        <EditButton />
        {record && record.workStatus === workStatusExcludingReviewed[1].workStatusType ? (
          <ButtonWithDisabledTooltip
            color="primary"
            onClick={() => setIsTreatDialogOpen(true)}
            label="申請を受理する"
          ></ButtonWithDisabledTooltip>
        ) : null}
        {record && record.workStatus === workStatusExcludingReviewed[1].workStatusType ? (
          <Button color="error" onClick={() => setIsRejectDialogOpen(true)} label="申請を棄却する" />
        ) : null}
      </TopToolbar>
    );
  };
  return (
    <Show title={<BookTitle />} actions={<ListActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="isbn" />
        <TextField source="latestIsbn" />
        <TextField source="operator" />
        <TextField source="confirmerId" />
        <TranslatedField source="workStatus" prefix="enum.workStatus" />
        <TextField source="publisherInfo.name" />
        <TextField source="pdfDataUrl" />
        <TranslatedField source="allowPDFPublish" prefix="enum.allowPDFPublish" />
        <TranslatedField source="allowWebPublish" prefix="enum.allowWebPublish" />
        <TranslatedField source="allowDownloadAndPrint" prefix="enum.allowDownloadAndPrint" />
        <TranslatedField source="allowCopyContent" prefix="enum.allowCopyContent" />
        <TranslatedField source="allowAddLink" prefix="enum.allowAddLink" />
        <TextField source="tosNotAllowedPage" />
        <TranslatedField source="isExistCoverImage" prefix="enum.isExistCoverImage" />
        <TextField source="bookTitle" />
        <TextField source="coverAuthor" />
        <TextField source="colophonPageNumber" />
        <TextField source="colophonAuthor" />
        <TextField source="bookVersion" />
        <TextField source="fullTitle" />
        <TextField source="printerInfo.name" />
        <DateField source="startPublicationDate" />
        <WrapperField label="LS掲載期間">
          <DatetimeField source="lsStartPublicationDate" />
          〜
          <DatetimeField source="lsEndPublicationDate" />
        </WrapperField>
        <TextField source="printing" />
        <TranslatedField source="bookType" prefix="enum.bookType" />
        <TranslatedField source="purchaseType" prefix="enum.purchaseType" />
        <DatetimeField source="reservableFrom" />
        <TextField source="clientCode" />
      </SimpleShowLayout>
      <UpdateConfirm
        resource="books/treat"
        isOpen={isTreatDialogOpen}
        title="申請を受理する"
        content="申請を受理しますがよろしいですか?"
        confirm="受理する"
        onClose={() => {
          setIsTreatDialogOpen(false);
          redirect('/books');
        }}
      />
      <UpdateConfirm
        resource="books/reject"
        isOpen={isRejectDialogOpen}
        title="申請を棄却する"
        content="申請を棄却しますがよろしいですか?"
        confirm="棄却する"
        onClose={() => {
          setIsRejectDialogOpen(false);
          redirect('/books');
        }}
      />
    </Show>
  );
};
