import React from 'react';
import { List, Datagrid, TextField, NumberField, DateField, ListProps } from 'react-admin';
import CategoryIcon from '@mui/icons-material/Category';

export const FormatCategoryIcon = CategoryIcon;

export const FormatCategoryList: React.FC<ListProps> = (props) => (
  <List {...props} title="書式カテゴリ一覧">
    <Datagrid bulkActionButtons={false}>
      <NumberField source="id" />
      <TextField source="name" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
