import * as React from 'react';
import { Confirm, useNotify, useRecordContext, useRefresh, useUpdate } from 'react-admin';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircle from '@mui/icons-material/CheckCircle';

type UpdateConfirmProps = {
  resource: string;
  isOpen: boolean;
  title: string;
  content: string;
  confirm: string;
  onClose: () => void;
};

export const UpdateConfirm = (props: UpdateConfirmProps) => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const [update, { isLoading }] = useUpdate();
  if (!record) return null;
  return (
    <Confirm
      isOpen={props.isOpen}
      loading={isLoading}
      title={props.title}
      content={props.content}
      confirm={props.confirm}
      ConfirmIcon={() => (isLoading ? <PendingIcon /> : <CheckCircle />)}
      onConfirm={async () => {
        try {
          await update(props.resource, { id: record?.id }, { mutationMode: 'pessimistic', returnPromise: true });
          props.onClose();
          notify('notification.simpleUpdated', {
            type: 'success',
          });
          refresh();
        } catch (e) {
          console.error(JSON.stringify(e));
          props.onClose();
          notify(`error.${e.message ?? 'unknown'}`, {
            type: 'error',
          });
        }
      }}
      onClose={props.onClose}
    />
  );
};

export default UpdateConfirm;
