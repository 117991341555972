import * as React from 'react';
import { List, Datagrid, TextField, TopToolbar, CreateButton, EditButton, Pagination } from 'react-admin';
import FactoryIcon from '@mui/icons-material/Factory';

export const PrinterIcon = FactoryIcon;

const ListActions: React.FC = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

export const PrinterList: React.FC = () => {
  return (
    <List actions={<ListActions />} perPage={1000} pagination={<Pagination rowsPerPageOptions={[10, 100, 1000]} />}>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="id" />
        <TextField source="name" sortable={false} />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export * from './create';
export * from './update';
