import * as React from 'react';
import {
  AutocompleteInput,
  CreateButton,
  Datagrid,
  EditButton,
  List,
  Pagination,
  ReferenceInput,
  SelectArrayInput,
  TextField,
  TopToolbar,
  DeleteButton,
} from 'react-admin';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import TranslatedField from 'src/components/TranslatedField';
import { workStatusExcludingReviewed } from './field';
import DebouncedTextInput from 'src/components/DebouncedTextInput';

export const BookIcon = MenuBookIcon;
const ListActions: React.FC = () => (
  <TopToolbar>
    <CreateButton label="PDF新規登録" />
  </TopToolbar>
);
const filters = [
  <DebouncedTextInput source="isbn" alwaysOn />,
  <DebouncedTextInput source="fullTitle" alwaysOn />,
  <DebouncedTextInput source="coverAuthor" alwaysOn />,
  <ReferenceInput source="publisherId" reference="publishers" alwaysOn>
    <AutocompleteInput optionText="name" label="resources.publishers.name" sx={{ width: 300 }} />
  </ReferenceInput>,
  <SelectArrayInput
    source="workStatus"
    choices={workStatusExcludingReviewed}
    optionValue={'workStatusType'}
    alwaysOn
  />,
];

export const BookList: React.FC = () => {
  return (
    <List
      actions={<ListActions />}
      filters={filters}
      perPage={1000}
      pagination={<Pagination rowsPerPageOptions={[10, 100, 1000]} />}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="id" />
        <TextField source="isbn" sortable={false} />
        <TextField source="fullTitle" sortable={false} />
        <TextField source="publisherInfo.name" sortable={false} />
        <TextField source="coverAuthor" sortable={false} />
        <TranslatedField source="allowPDFPublish" prefix="enum.allowPDFPublish" sortable={false} />
        <TranslatedField source="allowWebPublish" prefix="enum.allowWebPublish" sortable={false} />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export * from './create';
export * from './update';
