import * as React from 'react';
import { DateTimeInputProps, DateTimeInput } from 'react-admin';

/**
browserの<input type="datetime-local">を使用するので、valueはyyyy-MM-ddThh:mmであることが保証される。
react-adminが標準で用意しているDateTimeInputのparseは
const parseDateTime = (value: string) => new Date(value);
となっている。
*/
const parseDateTimeAsJST = (value: string) => new Date(`${value}:00+09:00`);

const CustomDateTimeInput: React.FC<DateTimeInputProps> = (props) => {
  const newProps: DateTimeInputProps = {
    parse: parseDateTimeAsJST,
    defaultValue: null,
    ...props,
  };
  return <DateTimeInput {...newProps} />;
};
export default CustomDateTimeInput;
