import * as React from 'react';
import { List, Datagrid, TextField, TopToolbar } from 'react-admin';
import SecurityIcon from '@mui/icons-material/Security';

export const PermissionIcon = SecurityIcon;

const ListActions: React.FC = (props) => <TopToolbar />;

export const PermissionList: React.FC = (props) => (
  <List actions={<ListActions />} pagination={false} perPage={1000}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <TextField source="description" sortable={false} />
    </Datagrid>
  </List>
);
