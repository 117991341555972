import * as React from 'react';
import { useGetOne } from 'react-admin';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

type UserCountTimelineProps = {
  id?: string;
};

type UserCountTimelineData = {
  id: string;
  total: number;
  data: {
    id: number;
    date: string;
    count: number;
  }[];
};

const UserCountTimeline = (props: UserCountTimelineProps) => {
  const { data } = useGetOne<UserCountTimelineData>('userCountTimelines', { id: props.id || '' });

  if (!data) {
    return null;
  }

  const dataList = data.data.map((timeline) => ({
    date: new Date(timeline.date).toLocaleDateString(),
    activeAccount: timeline.count,
  }));
  return (
    <div className="container" style={{ width: '100%' }}>
      <LineChart width={700} height={300} data={dataList}>
        <CartesianGrid strokeDasharray="5 1" />
        <XAxis dataKey="date" interval={0} />
        <YAxis dataKey="activeAccount" allowDecimals={false} />
        <Line type="monotone" dataKey="activeAccount" stroke="#ff0092" strokeWidth={2} />
        <Legend />
        <Tooltip />
      </LineChart>
    </div>
  );
};
export default UserCountTimeline;
