import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  CreateButton,
} from 'react-admin';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DatetimeField from 'src/components/DatetimeField';

export const AdminUserIcon = AdminPanelSettingsIcon;

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

export const AdminUserCreate: React.FC = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="legalscapeUserId" reference="users" filter={{ oid: 'legalscape' }}>
        <AutocompleteInput optionText="name" label="管理画面に追加するユーザー" sx={{ width: 300 }} />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const AdminUserList: React.FC = () => (
  <List actions={<ListActions />}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="firebaseId" sortable={false} />
      <TextField source="name" />
      <DatetimeField source="createdAt" />
    </Datagrid>
  </List>
);
