import * as React from 'react';
import { FunctionField, RaRecord, TextFieldProps } from 'react-admin';

const JobTitleField = (props: TextFieldProps) => {
  const render = (record?: RaRecord) => {
    if (record) {
      if (record['jobTitle']) {
        return record['jobTitle']['name'];
      }
      if (record['jobTitleId']) {
        return `不明(id: ${record['jobTitleId']})`;
      }
    }
    return '未設定';
  };
  return <FunctionField {...props} render={render} />;
};
export default JobTitleField;
