import * as React from 'react';
import {
  TextField,
  SimpleShowLayout,
  Show,
  ArrayField,
  Datagrid,
  TopToolbar,
  useRecordContext,
  Labeled,
  Button,
} from 'react-admin';
import DatetimeField from '../../components/DatetimeField';
import { useState } from 'react';
import UpdateConfirm from '../../components/UpdateConfirm';
import JobTitleField from '../../components/JobTitleField';
import { RequestStatus } from 'src/components/RequestStatus';
import { RequestType } from 'src/components/RequestType';
import { RequestDetail } from './types';
import {
  canCreateAddAccountRequest,
  canCreateEditAccountRequest,
  canCreateDeleteAccountRequest,
  canTreatEnrollmentRequest,
} from '../organization/state';
import { ButtonWithDisabledTooltip } from 'src/components/ButtonWithDisabledTooltip';
import ContractUserCountFields from 'src/components/ContractUserCountFields';
import TranslatedField from 'src/components/TranslatedField';
import UserCountTimeline from '../../components/UserCountTimeline';

function canTreat(record: RequestDetail): boolean {
  // listから遷移したときに最初の描画ではList APIの結果のオブジェクトが渡されてorganizationが存在しない
  if (!record.organization) {
    return false;
  }

  switch (record.requestType) {
    case 'addUserRequest':
      return canCreateAddAccountRequest(record.organization, new Date());
    case 'editUserRequest':
      return canCreateEditAccountRequest(record.organization, new Date());
    case 'deleteUserRequest':
      return canCreateDeleteAccountRequest(record.organization, new Date());
    case 'enrollmentRequest':
      return canTreatEnrollmentRequest(record.organization, new Date());
    default:
      return false;
  }
}
const UserCountTimelineField: React.FC = () => {
  const record = useRecordContext();
  // 一覧からの遷移時にはレコードの値として組織を取得できていない
  if (!record.organization) {
    return null;
  }
  return <UserCountTimeline id={record.organization.id} />;
};

export const RequestShow: React.FC = () => {
  const [isTreatDialogOpen, setIsTreatDialogOpen] = useState(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const ListActions: React.FC = () => {
    const record = useRecordContext<RequestDetail>();
    return (
      <TopToolbar>
        {record && record.requestStatus === 'applying' ? (
          <ButtonWithDisabledTooltip
            color="primary"
            onClick={() => setIsTreatDialogOpen(true)}
            disabled={!canTreat(record) ? 'messages.cannotTreatBecauseOfOrganizationState' : false}
            label="申請を受理する"
          ></ButtonWithDisabledTooltip>
        ) : null}
        {record && record.requestStatus === 'applying' ? (
          <Button color="error" onClick={() => setIsRejectDialogOpen(true)} label="申請を棄却する" />
        ) : null}
      </TopToolbar>
    );
  };

  return (
    <Show actions={<ListActions />}>
      <SimpleShowLayout>
        <TextField source="id" sortable={false} />
        <Labeled source="requestStatus">
          <RequestStatus />
        </Labeled>
        <Labeled source="requestType">
          <RequestType />
        </Labeled>
        <TextField source="organization.name" sortable={false} />
        <TranslatedField source="organization.pricingType" sortable={false} prefix="enum.pricingType" />
        <ContractUserCountFields />
        <TextField source="requestUser.name" sortable={false} />
        <DatetimeField source="createdAt" sortable={false} />
        <DatetimeField source="updatedAt" sortable={false} />
        <UserCountTimelineField />
        <ArrayField source="addUserRequestDetails">
          <Datagrid bulkActionButtons={false}>
            <TextField source="name" sortable={false} />
            <JobTitleField source="jobTitle.name" sortable={false} />
            <TextField source="newJobTitleName" sortable={false} />
            <TextField source="billable" sortable={false} />
            <TextField source="email" sortable={false} />
            <TextField source="objectId" sortable={false} />
            <DatetimeField source="preferredDate" sortable={false} />
          </Datagrid>
        </ArrayField>
        <ArrayField source="editUserRequestDetails">
          <Datagrid bulkActionButtons={false}>
            <TextField source="userId" sortable={false} />
            <TextField source="name" sortable={false} />
            <JobTitleField source="jobTitle.name" sortable={false} />
            <TextField source="newJobTitleName" sortable={false} />
            <TextField source="billable" sortable={false} />
            <TextField source="email" sortable={false} />
          </Datagrid>
        </ArrayField>
        <ArrayField source="deleteUserRequestDetails">
          <Datagrid bulkActionButtons={false}>
            <TextField source="userId" sortable={false} />
            <TextField source="name" sortable={false} />
            <JobTitleField source="jobTitle.name" sortable={false} />
            <TextField source="email" sortable={false} />
            <DatetimeField source="preferredDate" sortable={false} />
          </Datagrid>
        </ArrayField>
        <ArrayField source="enrollmentRequestDetail.users" label="resources.requests.fields.enrollmentRequestDetail">
          <Datagrid bulkActionButtons={false}>
            <TextField source="name" sortable={false} />
            <JobTitleField source="jobTitle.name" sortable={false} />
            <TextField source="newJobTitleName" sortable={false} />
            <TextField source="billable" sortable={false} />
            <TextField source="email" sortable={false} />
            <TextField source="objectId" sortable={false} />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
      <UpdateConfirm
        resource="requests/treat"
        isOpen={isTreatDialogOpen}
        title="申請を受理する"
        content="申請を受理しますがよろしいですか?"
        confirm="受理する"
        onClose={() => setIsTreatDialogOpen(false)}
      />
      <UpdateConfirm
        resource="requests/reject"
        isOpen={isRejectDialogOpen}
        title="申請を棄却する"
        content="申請を棄却しますがよろしいですか?"
        confirm="棄却する"
        onClose={() => setIsRejectDialogOpen(false)}
      />
    </Show>
  );
};
