import React from 'react';
import { List, Datagrid, TextField, NumberField, DateField, ListProps } from 'react-admin';

export const LabsWandhPlanList: React.FC<ListProps> = (props) => (
  <List {...props} title="W&Hプラン一覧">
    <Datagrid bulkActionButtons={false}>
      <NumberField source="id" />
      <TextField source="name" />
      <NumberField source="maxSearchTotal" />
      <NumberField source="maxSearchBoost" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);
