import * as React from 'react';
import {
  EditButton,
  ReferenceArrayField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  WrapperField,
  FunctionField,
} from 'react-admin';
import DatetimeField from 'src/components/DatetimeField';
import TranslatedField from 'src/components/TranslatedField';
import { DocRecord } from './index';

const DocTitle = () => {
  const record = useRecordContext();
  // ロード中は空
  if (!record) return null;
  return <span>{record.title}</span>;
};

export const DocShow: React.FC = () => {
  const ListActions: React.FC = () => {
    return (
      <TopToolbar>
        <EditButton />
      </TopToolbar>
    );
  };
  return (
    <Show title={<DocTitle />} actions={<ListActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="type" />
        <TextField source="title" />
        <FunctionField
          source="authors"
          render={(record?: { authors: string[] }) => {
            return record?.authors.join('、');
          }}
        />
        <TextField source="publisher" />
        <ReferenceArrayField source="packIds" reference="packs" sortable={false} />
        <FunctionField
          source="planIds"
          render={(record?: DocRecord) => {
            if (record?.planIds?.length) {
              return record.planIds.join('、');
            }
            return '';
          }}
        />
        <WrapperField label="公開期間">
          <DatetimeField source="availableFrom" />
          〜
          <DatetimeField source="availableUntil" />
        </WrapperField>
        <TranslatedField source="purchaseType" prefix="enum.purchaseType" />
        <DatetimeField source="reservableFrom" />
      </SimpleShowLayout>
    </Show>
  );
};
