export const workStatusExcludingReviewed = [
  { id: 1, name: 'enum.workStatus.pdfRegistered', workStatusType: 'pdfRegistered' },
  { id: 2, name: 'enum.workStatus.bibliographicInfoEntered', workStatusType: 'bibliographicInfoEntered' },
  { id: 4, name: 'enum.workStatus.remand', workStatusType: 'remand' },
];

export const reviewedWorkStatus = { id: 3, name: 'enum.workStatus.reviewed', workStatusType: 'reviewed' };

export const bookType = [
  { id: 1, name: 'enum.bookType.book', bookType: 'book' },
  { id: 2, name: 'enum.bookType.magazine', bookType: 'magazine' },
];

export const purchaseType = [
  { id: 1, name: 'enum.purchaseType.default', purchaseType: 'default' },
  { id: 2, name: 'enum.purchaseType.purchasable', purchaseType: 'purchasable' },
];
