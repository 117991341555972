import { FunctionField, RaRecord, useTranslate } from 'react-admin';
import { Stack, Typography } from '@mui/material';

const ContractUserCountFields = () => {
  const translate = useTranslate();

  return (
    <Stack spacing={0.25}>
      <Stack direction="row" spacing={0.5}>
        <Typography variant="caption" color="textSecondary">
          {translate('resources.organizations.fields.activeContractUserCount')}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          /
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {translate('resources.organizations.fields.maxContractUserCount')}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={0.5}>
        <FunctionField
          sortable={false}
          render={(record: RaRecord) =>
            // 組織詳細のときは前者、申請詳細のときは後者が参照される
            record?.activeContractUserCount ?? record?.organization?.activeContractUserCount ?? 0
          }
        />
        <Typography variant="body2">/</Typography>
        <FunctionField
          sortable={false}
          render={(record: RaRecord) =>
            // 組織詳細のときは前者、申請詳細のときは後者が参照される
            record?.maxContractUserCount ?? record?.organization?.maxContractUserCount ?? '未設定'
          }
        />
      </Stack>
    </Stack>
  );
};
export default ContractUserCountFields;
