import React from 'react';
import { Button, ButtonProps, useTranslate } from 'react-admin';
import { Tooltip } from '@mui/material';

type Props = Omit<ButtonProps, 'disabled'> & {
  disabled?: boolean | string;
};

export const ButtonWithDisabledTooltip: React.FC<Props> = (props) => {
  const translate = useTranslate();

  const { disabled: disabledOrTooltipText, ...buttonProps } = props;
  if (typeof disabledOrTooltipText === 'string') {
    return (
      <Tooltip title={translate(disabledOrTooltipText)}>
        <span>
          <Button {...buttonProps} disabled />
        </span>
      </Tooltip>
    );
  } else {
    return <Button {...buttonProps} disabled={disabledOrTooltipText} />;
  }
};
