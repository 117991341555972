import * as React from 'react';
import { SimpleForm, Create, AutocompleteInput, ReferenceInput } from 'react-admin';

export const TrialUserRequestCreate: React.FC = () => {
  return (
    <Create>
      <SimpleForm>
        <ReferenceInput source="organization" reference="organizations" alwaysOn>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
