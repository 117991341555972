import * as React from 'react';
import {
  TextField,
  SimpleShowLayout,
  Show,
  ArrayField,
  Datagrid,
  TopToolbar,
  useRecordContext,
  UrlField,
  Button,
} from 'react-admin';
import TranslatedField from '../../components/TranslatedField';
import DatetimeField from '../../components/DatetimeField';
import { useState } from 'react';
import UpdateConfirm from '../../components/UpdateConfirm';
import { canCreateTrialRequest } from '../organization/state';
import { ButtonWithDisabledTooltip } from 'src/components/ButtonWithDisabledTooltip';
import { TrialUserReuqestDetail } from './types';

function canTreat(record: TrialUserReuqestDetail): boolean {
  // listから遷移したときに最初の描画ではList APIの結果のオブジェクトが渡されてorganizationが存在しない
  if (!record.organization) {
    return false;
  }

  return canCreateTrialRequest(record.organization, new Date());
}

export const TrialUserRequestShow: React.FC = () => {
  const [isTreatDialogOpen, setIsTreatDialogOpen] = useState(false);
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const ListActions: React.FC = () => {
    const record = useRecordContext<TrialUserReuqestDetail>();
    return (
      <TopToolbar>
        {record && record.requestStatus === 'applying' ? (
          <ButtonWithDisabledTooltip
            color="primary"
            onClick={() => setIsTreatDialogOpen(true)}
            disabled={!canTreat(record) ? 'messages.cannotTreatBecauseOfOrganizationState' : false}
            label="申請を受理する"
          />
        ) : null}
        {record && record.requestStatus === 'applying' ? (
          <Button color="error" onClick={() => setIsRejectDialogOpen(true)} label="申請を棄却する" />
        ) : null}
      </TopToolbar>
    );
  };

  return (
    <Show actions={<ListActions />}>
      <SimpleShowLayout>
        <TextField source="id" sortable={false} />
        <UrlField source="trialUrl" sortable={false} />
        <TranslatedField source="requestStatus" sortable={false} prefix="enum.trialRequestStatus" />
        <DatetimeField source="createdAt" sortable={false} />
        <DatetimeField source="updatedAt" sortable={false} />
        <ArrayField source="trialUserRequestDetails">
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" sortable={false} />
            <TextField source="name" sortable={false} />
            <TextField source="email" sortable={false} />
            <TextField source="objectId" sortable={false} />
            <DatetimeField source="validFrom" sortable={false} />
            <DatetimeField source="createdAt" sortable={false} />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
      <UpdateConfirm
        resource="trialUserRequests/treat"
        isOpen={isTreatDialogOpen}
        title="申請を受理する"
        content="申請を受理しますがよろしいですか?"
        confirm="受理する"
        onClose={() => setIsTreatDialogOpen(false)}
      />
      <UpdateConfirm
        resource="trialUserRequests/reject"
        isOpen={isRejectDialogOpen}
        title="申請を棄却する"
        content="申請を棄却しますがよろしいですか?"
        confirm="棄却する"
        onClose={() => setIsRejectDialogOpen(false)}
      />
    </Show>
  );
};
