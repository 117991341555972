import * as React from 'react';
import { useInput } from 'react-admin';

interface HiddenInputProps {
  source: string;
  defaultValue?: unknown;
}

const HiddenInput: React.FC<HiddenInputProps> = (props: HiddenInputProps) => {
  const { field } = useInput(props);
  return <input type="hidden" {...field}></input>;
};

export default HiddenInput;
