import * as React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
} from 'react-admin';
import CustomDateTimeInput from 'src/components/DatetimeInput';
import { FormGlobalError } from 'src/components/FormGlobalError';
import HiddenInput from 'src/components/HiddenInput';
import { bookType, purchaseType } from '../book/field';

const ReviewedBookEditToolbar: React.FC = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const ReviewedBookEdit: React.FC = () => {
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm shouldUnregister={true} toolbar={<ReviewedBookEditToolbar />}>
        <FormGlobalError />

        <HiddenInput source="updatedAtMillis" />
        <HiddenInput source="pdfDataUrl" />
        <HiddenInput source="operator" />
        <HiddenInput source="confirmerId" />

        <TextInput source="isbn" validate={required()} />
        <TextInput source="latestIsbn" />
        <ReferenceInput source="publisherInfo.id" reference="publishers">
          <SelectInput optionText="name" validate={required()} />
        </ReferenceInput>
        <TextInput source="pdfDataUrl" />
        <BooleanInput source="allowPDFPublish" />
        <BooleanInput source="allowWebPublish" />
        <BooleanInput source="allowDownloadAndPrint" />
        <BooleanInput source="allowCopyContent" />
        <BooleanInput source="allowAddLink" />
        <TextInput source="tosNotAllowedPage" />
        <BooleanInput source="isExistCoverImage" />
        <TextInput source="bookTitle" fullWidth />
        <TextInput source="coverAuthor" fullWidth />
        <NumberInput source="colophonPageNumber" />
        <TextInput source="colophonAuthor" fullWidth />
        <TextInput source="bookVersion" />
        <TextInput source="fullTitle" fullWidth />
        <ReferenceInput source="printerInfo.id" reference="printers">
          <AutocompleteInput optionText="name" label="resources.printers.name" sx={{ width: 300 }} />
        </ReferenceInput>
        <DateInput source="startPublicationDate" />
        <CustomDateTimeInput source="lsStartPublicationDate" />
        <CustomDateTimeInput source="lsEndPublicationDate" />
        <TextInput source="printing" />
        <SelectInput source="bookType" choices={bookType} optionValue={'bookType'} validate={required()} />
        <SelectInput source="purchaseType" choices={purchaseType} optionValue={'purchaseType'} validate={required()} />
        <CustomDateTimeInput source="reservableFrom" />
        <TextInput source="clientCode" fullWidth />
      </SimpleForm>
    </Edit>
  );
};
