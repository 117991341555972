import * as React from 'react';
import { FunctionField, useTranslate, FunctionFieldProps, RaRecord } from 'react-admin';
import get from 'lodash/get';

type TranslatedFieldProps = Omit<FunctionFieldProps, 'render'> & {
  prefix?: string;
};

const TranslatedField: React.FC<TranslatedFieldProps> = (props) => {
  const translate = useTranslate();
  const translatePrefix = props.prefix ? `${props.prefix}.` : '';
  const render = (record?: RaRecord) =>
    record &&
    props.source &&
    get(record, props.source ?? '') !== undefined &&
    get(record, props.source ?? '') !== null &&
    translate(translatePrefix + get(record, props.source ?? ''));

  return <FunctionField {...props} render={render} />;
};
export default TranslatedField;
