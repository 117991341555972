import * as React from 'react';
import { Box } from '@mui/material';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  useRedirect,
  useNotify,
  useDataProvider,
} from 'react-admin';
import { FormGlobalError } from 'src/components/FormGlobalError';
import useFormValues from 'src/hooks/useFormValues';
import { FieldValues, useFormContext } from 'react-hook-form';
import { bookType } from './field';

export const ContractInputs: React.FC = () => {
  const { publisherInfo, isPublisherEditable, bookType } = useFormValues();
  const dataProvider = useDataProvider();
  const { setValue } = useFormContext<FieldValues>();

  const fetchPublishersData = React.useCallback(async () => {
    if (!publisherInfo.id) {
      return;
    }

    try {
      const { data } = await dataProvider.getOne('publishers', { id: publisherInfo.id });
      if (bookType === 'book') {
        setValue('allowPDFPublish', data.allowPDFPublish);
        setValue('allowWebPublish', data.allowWebPublish);
        setValue('allowDownloadAndPrint', data.allowDownloadAndPrint);
        setValue('allowCopyContent', data.allowCopyContent);
      }
      if (bookType === 'magazine') {
        setValue('allowPDFPublish', data.magazineAllowPDFPublish);
        setValue('allowWebPublish', data.magazineAllowWebPublish);
        setValue('allowDownloadAndPrint', data.magazineAllowDownloadAndPrint);
        setValue('allowCopyContent', data.magazineAllowCopyContent);
      }
    } catch (error) {
      setValue('allowPDFPublish', false);
      setValue('allowWebPublish', false);
      setValue('allowDownloadAndPrint', false);
      setValue('allowCopyContent', false);
    }
  }, [dataProvider, publisherInfo?.id, setValue, bookType]);

  React.useEffect(() => {
    fetchPublishersData();
  }, [fetchPublishersData, isPublisherEditable, bookType]);

  return (
    <>
      <BooleanInput source="allowPDFPublish" disabled={!isPublisherEditable} />
      <BooleanInput source="allowWebPublish" disabled={!isPublisherEditable} />
      <BooleanInput source="allowDownloadAndPrint" disabled={!isPublisherEditable} />
      <BooleanInput source="allowCopyContent" disabled={!isPublisherEditable} />
    </>
  );
};

export const BookCreate: React.FC = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify('新規書誌情報を登録しました');
    redirect('/books');
  };

  return (
    <Create mutationOptions={{ onSuccess }}>
      <SimpleForm>
        <FormGlobalError />
        <ReferenceInput
          reference="publishers"
          source="publisherInfo.id"
          sort={{ field: 'id', order: 'ASC' }}
          perPage={1000}
        >
          <SelectInput validate={required()} optionText="name" defaultValue={1} label="resources.publishers.name" />
        </ReferenceInput>
        <TextInput
          validate={required()}
          label="resources.books.fields.pdfDataUrl"
          source="pdfDataUrl"
          multiline
          fullWidth
        />
        <SelectInput source="bookType" choices={bookType} optionValue={'bookType'} validate={required()} />
        <div style={{ color: 'red' }}>※ 以下の条件は今回入稿する全ての書籍に適用されます</div>
        <BooleanInput source="allowAddLink" />
        <BooleanInput source="isPublisherEditable" label="利用条件を変更" />
        <Box ml={4}>
          <ContractInputs />
        </Box>
      </SimpleForm>
    </Create>
  );
};
