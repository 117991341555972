import * as React from 'react';
import { DateFieldProps, DateField as RADateField } from 'react-admin';

const DatetimeField: React.FC<DateFieldProps> = ({ options, ...rest }) => {
  const props = {
    locales: 'ja-JP',
    options: {
      timeZone: 'Asia/Tokyo',
      year: 'numeric',
      month: 'narrow',
      day: 'numeric',
      hour: '2-digit',
      minute: 'numeric',
      second: 'numeric',
      ...options,
    },
    ...rest,
  };
  return <RADateField {...props} showTime />;
};
export default DatetimeField;
