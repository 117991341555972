import * as React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Button,
  DateInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  required,
  useDataProvider,
} from 'react-admin';
import { FormGlobalError } from 'src/components/FormGlobalError';
import useFormValues from 'src/hooks/useFormValues';
import HiddenInput from 'src/components/HiddenInput';
import { bookType, purchaseType } from './field';
import { FieldValues, useFormContext } from 'react-hook-form';
import CustomDateTimeInput from 'src/components/DatetimeInput';

const BookEditToolbar: React.FC = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const BookEditComponent: React.FC = () => {
  const { isbn } = useFormValues();
  const dataProvider = useDataProvider();
  const { setValue } = useFormContext<FieldValues>();

  const handleButtonClick = async () => {
    try {
      if (isbn) {
        const { data } = await dataProvider.getOne('books/linkedIsbn', { id: isbn });
        if (data.id) {
          setValue('bookTitle', data.bookTitle);
          setValue('colophonAuthor', data.colophonAuthor);
          setValue('startPublicationDate', data.startPublicationDate.split('T')[0]);
        }
      }
    } catch (error) {
      console.error('API call error', error);
    }
  };

  return (
    <>
      <HiddenInput source="updatedAtMillis" />
      <HiddenInput source="latestIsbn" />
      <HiddenInput source="lsStartPublicationDate" />
      <HiddenInput source="lsEndPublicationDate" />
      <HiddenInput source="pdfDataUrl" />
      <HiddenInput source="allowPDFPublish" />
      <HiddenInput source="allowWebPublish" />
      <HiddenInput source="allowDownloadAndPrint" />
      <HiddenInput source="allowCopyContent" />
      <HiddenInput source="allowAddLink" />
      <HiddenInput source="operator" />
      <HiddenInput source="confirmer" />
      <HiddenInput source="pdfDataUrl" />
      <HiddenInput source="publisherInfo.id" />

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextInput source="isbn" validate={required()} />
        <Button label="NDL Searchから情報を自動入力" color="primary" variant="contained" onClick={handleButtonClick} />
      </div>
      <TextInput source="tosNotAllowedPage" />
      <BooleanInput source="isExistCoverImage" />
      <TextInput source="bookTitle" fullWidth />
      <TextInput source="coverAuthor" fullWidth />
      <NumberInput source="colophonPageNumber" />
      <TextInput source="colophonAuthor" fullWidth />
      <TextInput source="bookVersion" />
      <TextInput source="fullTitle" fullWidth />
      <ReferenceInput source="printerInfo.id" reference="printers">
        <AutocompleteInput optionText="name" label="resources.printers.name" sx={{ width: 300 }} />
      </ReferenceInput>
      <DateInput source="startPublicationDate" />
      <TextInput source="printing" />
      <SelectInput source="bookType" choices={bookType} optionValue={'bookType'} validate={required()} />
      <SelectInput source="purchaseType" choices={purchaseType} optionValue={'purchaseType'} validate={required()} />
      <CustomDateTimeInput source="reservableFrom" />
      <TextInput source="clientCode" style={{ width: '50%' }} />
    </>
  );
};

export const BookEdit: React.FC = () => {
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm shouldUnregister={true} toolbar={<BookEditToolbar />}>
        <FormGlobalError />
        <BookEditComponent />
      </SimpleForm>
    </Edit>
  );
};
