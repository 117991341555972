import * as React from 'react';
import { Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

const PrinterTitle = () => {
  const record = useRecordContext();
  // ロード中は空
  if (!record) return null;
  return <span>{record.name}</span>;
};

export const PrinterShow: React.FC = () => {
  return (
    <Show title={<PrinterTitle />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
      </SimpleShowLayout>
    </Show>
  );
};
