import { useRecordContext } from 'react-admin';
import { Box } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import TranslatedField from './TranslatedField';

export const RequestStatus: React.FC = () => {
  const record = useRecordContext();

  if (record && record.requestStatus) {
    return (
      <Box display="flex" alignItems="center">
        {record.requestStatus === 'applying' && <ScheduleIcon color="primary" />}
        {record.requestStatus === 'treated' && <CheckCircleOutlineIcon color="success" />}
        {record.requestStatus === 'rejected' && <WarningIcon color="error" />}
        <TranslatedField source="requestStatus" sortable={false} prefix="enum.requestStatus" sx={{ marginLeft: 1 }} />
      </Box>
    );
  }

  return null;
};
