import * as React from 'react';
import { TextInput, TextInputProps } from 'react-admin';
import debounce from 'lodash/debounce';

// debounceが対応していないreact-adminのversionだったのでdebounceの機能を持ったTextInputを自作
const DebouncedTextInput: React.FC<TextInputProps> = (props) => {
  const [value, setValue] = React.useState('');

  const handleChange = debounce((value: string) => {
    if (props.onChange) {
      props.onChange(value);
    }
  }, 500);

  return (
    <TextInput
      {...props}
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        handleChange(e.target.value);
      }}
    />
  );
};
export default DebouncedTextInput;
