import * as React from 'react';

// https://femto.hashnode.dev/redirecting-to-external-urls-in-react-router-dom-v6
const ExternlLink: React.FC<{ url: string }> = ({ url }) => {
  React.useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
};
export default ExternlLink;
