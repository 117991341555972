import * as React from 'react';
import {
  DateField,
  EditButton,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  WrapperField,
} from 'react-admin';
import DatetimeField from 'src/components/DatetimeField';
import TranslatedField from 'src/components/TranslatedField';

const ReviewedBookTitle = () => {
  const record = useRecordContext();
  // ロード中は空
  if (!record) return null;
  return <span>{record.name}</span>;
};

export const ReviewedBookShow: React.FC = () => {
  const ListActions: React.FC = () => {
    return (
      <TopToolbar>
        <EditButton />
      </TopToolbar>
    );
  };
  return (
    <Show title={<ReviewedBookTitle />} actions={<ListActions />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="isbn" />
        <TextField source="latestIsbn" />
        <TextField source="operator" />
        <TextField source="confirmerId" />
        <TranslatedField source="workStatus" prefix="enum.workStatus" />
        <TextField source="publisherInfo.name" />
        <TextField source="pdfDataUrl" />
        <TranslatedField source="allowPDFPublish" prefix="enum.allowPDFPublish" />
        <TranslatedField source="allowWebPublish" prefix="enum.allowWebPublish" />
        <TranslatedField source="allowDownloadAndPrint" prefix="enum.allowDownloadAndPrint" />
        <TranslatedField source="allowCopyContent" prefix="enum.allowCopyContent" />
        <TranslatedField source="allowAddLink" prefix="enum.allowAddLink" />
        <TextField source="tosNotAllowedPage" />
        <TranslatedField source="isExistCoverImage" prefix="enum.isExistCoverImage" />
        <TextField source="bookTitle" />
        <TextField source="coverAuthor" />
        <TextField source="colophonPageNumber" />
        <TextField source="colophonAuthor" />
        <TextField source="bookVersion" />
        <TextField source="fullTitle" />
        <TextField source="printerInfo.name" />
        <DateField source="startPublicationDate" />
        <WrapperField label="LS掲載期間">
          <DatetimeField source="lsStartPublicationDate" />
          〜
          <DatetimeField source="lsEndPublicationDate" />
        </WrapperField>
        <TextField source="printing" />
        <TranslatedField source="bookType" prefix="enum.bookType" />
        <TranslatedField source="purchaseType" prefix="enum.purchaseType" />
        <DatetimeField source="reservableFrom" />
        <TextField source="clientCode" />
      </SimpleShowLayout>
    </Show>
  );
};
