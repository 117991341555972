import { CreateParams } from 'react-admin';

type CreatePdfPostParams = {
  bookId: string;
  file: {
    rawFile: File;
    src?: string;
    title?: string;
  };
};

export const CreatePdfPostData = (params: CreateParams<CreatePdfPostParams>) => {
  const formData = new FormData();
  params.data.bookId && formData.append('bookId', params.data.bookId);
  params.data.file.rawFile && formData.append('file', params.data.file.rawFile);

  return formData;
};
