import * as React from 'react';
import { Edit, SaveButton, SimpleForm, TextInput, Toolbar, required } from 'react-admin';
import { FormGlobalError } from 'src/components/FormGlobalError';
import HiddenInput from 'src/components/HiddenInput';

const PrinterEditToolbar: React.FC = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const PrinterEditComponent: React.FC = () => {
  return (
    <>
      <HiddenInput source="updatedAtMillis" />

      <TextInput source="name" validate={required()} />
    </>
  );
};

export const PrinterEdit: React.FC = () => {
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm shouldUnregister={true} toolbar={<PrinterEditToolbar />}>
        <FormGlobalError />
        <PrinterEditComponent />
      </SimpleForm>
    </Edit>
  );
};
