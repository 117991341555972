import * as React from 'react';
import { List, Datagrid, TextField, TopToolbar, UrlField, SelectInput, ShowButton, CreateButton } from 'react-admin';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import DatetimeField from 'src/components/DatetimeField';
import TranslatedField from '../../components/TranslatedField';

export const TrialUserRequestIcon = NotStartedIcon;

const ListActions: React.FC = () => (
  <TopToolbar>
    <CreateButton label="resources.trialUserRequests.action.create" />
  </TopToolbar>
);

const postFilters = [
  <SelectInput
    source="requestStatus"
    choices={[
      { id: 'beforeApplication', name: 'enum.trialRequestStatus.beforeApplication' },
      { id: 'applying', name: 'enum.trialRequestStatus.applying' },
      { id: 'processing', name: 'enum.trialRequestStatus.processing' },
      { id: 'treated', name: 'enum.trialRequestStatus.treated' },
      { id: 'rejected', name: 'enum.trialRequestStatus.rejected' },
    ]}
    alwaysOn
  />,
];

export const TrialUserRequestList: React.FC = () => (
  <List actions={<ListActions />} filters={postFilters} perPage={100}>
    <Datagrid bulkActionButtons={false}>
      <ShowButton />
      <TextField source="id" sortable={false} />
      <UrlField source="trialUrl" sortable={false} />
      <TextField source="subdomain" sortable={false} />
      <TranslatedField source="requestStatus" sortable={false} prefix="enum.trialRequestStatus" />
      <DatetimeField source="createdAt" sortable={false} />
      <DatetimeField source="updatedAt" sortable={false} />
    </Datagrid>
  </List>
);
