import { AutocompleteInput, Create, FileField, FileInput, ReferenceInput, required, SimpleForm } from 'react-admin';
import * as React from 'react';

export const PdfUploader: React.FC = () => {
  const filter = {
    workStatus: ['pdfRegistered', 'bibliographicInfoEntered', 'reviewed', 'remand'],
  };

  return (
    <Create resource="pdf">
      <SimpleForm>
        <ReferenceInput source="bookId" reference="books" filter={filter}>
          {/* TODO ISBN ではなく naturalId と表示する。現状は input_bibliographic_info テーブルのカラム名に合わせているため、 ISBN という表記をしています */}
          <AutocompleteInput
            validate={required()}
            optionText="isbn"
            label="ISBN"
            filterToQuery={(searchText) => ({ isbn: searchText })}
          />
        </ReferenceInput>
        <FileInput source="file" accept="application/pdf" multiple={false} validate={required()}>
          <FileField source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
};
