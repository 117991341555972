import * as React from 'react';
import { Create, SimpleForm, TextInput, required, useRedirect, useNotify } from 'react-admin';
import { FormGlobalError } from 'src/components/FormGlobalError';

export const PrinterCreate: React.FC = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onSuccess = () => {
    notify('印刷所を登録しました');
    redirect('/printers');
  };

  return (
    <Create mutationOptions={{ onSuccess }}>
      <SimpleForm>
        <FormGlobalError />
        <TextInput validate={required()} source="name" fullWidth />
      </SimpleForm>
    </Create>
  );
};
