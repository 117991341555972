import * as React from 'react';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
  email,
  required,
  TextInputProps,
  useGetOne,
  useTranslate,
  useRedirect,
} from 'react-admin';
import { Alert, Button, Box, Modal, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import useFormValues from 'src/hooks/useFormValues';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';

interface Organization {
  id: string;
  loginType: string;
}

const ObjectIdInput: FC<TextInputProps> = (props) => {
  const { organizationId } = useFormValues();
  const [showTextInput, setShowTextInput] = useState(false);
  const { data: organization } = useGetOne<Organization>(
    'organizations',
    { id: organizationId },
    { enabled: !!organizationId },
  );

  useEffect(() => {
    if (!organizationId) {
      setShowTextInput(false);
      return;
    }

    if (organization?.loginType === 'microsoft') {
      setShowTextInput(true);
    } else {
      setShowTextInput(false);
    }
  }, [organizationId, organization]);

  return <>{showTextInput && <TextInput {...props} />}</>;
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '3px',
  p: 4,
};

export const UserCreate: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const redirect = useRedirect();
  const translate = useTranslate();
  const onSuccess = (data: { password: string }) => {
    setMessage(translate('messages.createUser', { password: data.password }));
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    redirect('list');
  };
  return (
    <>
      <Modal open={open}>
        <Box sx={style}>
          <Typography>{message}</Typography>
          <Grid container justifyContent="flex-end">
            <Button sx={{ mt: 4 }} variant="contained" disableElevation startIcon={<CloseIcon />} onClick={handleClose}>
              閉じる
            </Button>
          </Grid>
        </Box>
      </Modal>
      <Create redirect="list" mutationOptions={{ onSuccess }}>
        <SimpleForm>
          <Alert severity="info" style={{ marginBottom: '1rem' }}>
            現在、ここで作れるユーザーは管理専用ユーザーのみです。
          </Alert>
          <ReferenceInput source="organizationId" reference="organizations">
            <AutocompleteInput
              validate={required()}
              optionText="name"
              label="resources.organizations.name"
              sx={{ width: 450 }}
            />
          </ReferenceInput>
          <TextInput validate={required()} source="name" sx={{ width: 300 }} />
          <TextInput validate={[required(), email()]} source="email" sx={{ width: 300 }} />
          <ObjectIdInput validate={required()} source="objectId" sx={{ width: 300 }} />
        </SimpleForm>
      </Create>
    </>
  );
};
