import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonProps } from 'react-admin';

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

export const LinkButton = (props: ButtonProps) => {
  return <Button component={Link} onClick={stopPropagation} {...props} />;
};
export default LinkButton;
