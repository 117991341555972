import * as React from 'react';
import type { Validator } from 'react-admin';
import { ArrayInput, FormDataConsumer, ReferenceInput, required, SelectInput, SimpleFormIterator } from 'react-admin';
import DatetimeInput from 'src/components/DatetimeInput';

export const PermissionsInput: React.FC = () => {
  const defaultValidFrom = new Date();
  defaultValidFrom.setHours(0, 0, 0, 0);

  type ValidatePermission = (
    value: number,
    values: { permissions: { id: number }[] },
    props: { source: string },
  ) => string | undefined;
  const validatePermission: ValidatePermission = (value, values, props) => {
    const { source } = props;
    const index = Number(source.split('.')[1]);

    let error;
    // パーミッション ID が重複しているものだけを抽出
    const duplicates = values.permissions.map(({ id }, i) => ({ id, i })).filter(({ id }) => id === value);
    // 重複が自分自身以外の場合はバリデーションエラー
    if (duplicates.findIndex(({ i }) => i === index) !== 0) {
      error = '権限が重複しています';
    }

    return error;
  };

  type ValidateValidUntil = (scopedFormData: {
    id: number;
    validFrom: Date | string;
    validUntil: Date | string | null;
  }) => Validator;
  const validateValidUntil: ValidateValidUntil = (scopedFormData) => {
    return (validUntil: Date | string | null, _values, _props) => {
      let error;
      const { validFrom } = scopedFormData;
      const _validFrom = validFrom ? new Date(validFrom) : validFrom;
      const _validUntil = validUntil ? new Date(validUntil) : validUntil;
      if (_validUntil && _validFrom && _validUntil <= _validFrom) {
        error = '適用終了日は適用開始日より未来の日付を入力してください';
      }

      return error;
    };
  };

  return (
    <>
      <ArrayInput source="permissions">
        <SimpleFormIterator disableReordering inline>
          <FormDataConsumer>
            {({ scopedFormData, getSource }) =>
              getSource && (
                <>
                  <ReferenceInput
                    reference="permissions"
                    source={getSource('id')}
                    validate={[required()]}
                    sort={{ field: 'id', order: 'ASC' }}
                    perPage={1000}
                  >
                    <SelectInput optionText="name" validate={[required(), validatePermission]} />
                  </ReferenceInput>
                  <DatetimeInput
                    source={getSource('validFrom')}
                    defaultValue={defaultValidFrom}
                    validate={[required()]}
                  />
                  <DatetimeInput source={getSource('validUntil')} validate={validateValidUntil(scopedFormData)} />
                </>
              )
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};
