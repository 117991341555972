import NotStartedIcon from '@mui/icons-material/NotStarted';
import * as React from 'react';
import {
  Button,
  Confirm,
  Datagrid,
  ListContextProvider,
  useDataProvider,
  useList,
  useNotify,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { useMutation } from 'react-query';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircle from '@mui/icons-material/CheckCircle';
import TranslatedField from 'src/components/TranslatedField';

export const TrialUserRequestIcon = NotStartedIcon;

type TaskDefinition = {
  id: string;
  hasParameters: boolean;
};

const tasks: TaskDefinition[] = [
  {
    id: 'recreateAllOrgViews',
    hasParameters: false,
  },
];

export const ExecuteButton: React.FC = () => {
  const notify = useNotify();
  const translate = useTranslate();
  const { id, hasParameters } = useRecordContext<TaskDefinition>();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const dataProvider = useDataProvider();
  const { mutateAsync, isLoading } = useMutation(() => dataProvider.invoke(id, {}));

  const name = translate(`resources.tasks.ids.${id}`);

  if (hasParameters) {
    throw new Error('not implemented');
  }

  return (
    <div>
      <Button color="primary" onClick={() => setIsDialogOpen(true)} label="label.invoke" />
      <Confirm
        isOpen={isDialogOpen}
        loading={isLoading}
        title={name}
        content={translate('messages.confirm_invoke', { name })}
        confirm={'label.invoke'}
        ConfirmIcon={() => (isLoading ? <PendingIcon /> : <CheckCircle />)}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={async () => {
          try {
            await mutateAsync();
            notify('notification.completed', {
              type: 'success',
            });
          } catch (e) {
            console.error(e);
            notify('error.unknown', {
              type: 'error',
            });
          } finally {
            setIsDialogOpen(false);
          }
        }}
      />
    </div>
  );
};

export const TaskList: React.FC = () => {
  const listContext = useList({ data: tasks });
  return (
    <ListContextProvider value={listContext}>
      <Datagrid bulkActionButtons={false}>
        <TranslatedField source="id" prefix="resources.tasks.ids" />
        <ExecuteButton />
      </Datagrid>
    </ListContextProvider>
  );
};
