export type DurationState = 'no' | 'before' | 'within' | 'after';

export function getDurationState(now: Date, from: Date | null, until: Date | null): DurationState {
  if (from !== null && until !== null && from.getTime() >= until.getTime()) {
    throw Error(`fromとuntilの前後関係が逆転しています: ${from} 〜 ${until}`);
  }

  if (from === null && until === null) {
    return 'no';
  }

  if (from !== null && now.getTime() < from.getTime()) {
    return 'before';
  }

  if (until !== null && until.getTime() < now.getTime()) {
    return 'after';
  }

  return 'within';
}
