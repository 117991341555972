import React from 'react';
import { InputHelperText } from 'react-admin';
import { FormHelperText } from '@mui/material';
import { useFormContext } from 'react-hook-form';

export const FormGlobalError: React.FC = () => {
  const {
    formState: { isDirty, isSubmitted, errors },
  } = useFormContext();

  const globalErrors = errors.root ?? {};

  if (Object.entries(globalErrors).length === 0) {
    return null;
  }

  return (
    <div>
      {Object.entries(globalErrors).map(([key, error]) => {
        return (
          <FormHelperText error={isDirty || isSubmitted} key={key}>
            <InputHelperText touched={isDirty || isSubmitted} error={error.message} />
          </FormHelperText>
        );
      })}
    </div>
  );
};
