import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TopToolbar,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  ShowButton,
  FunctionField,
  RaRecord,
} from 'react-admin';
import ApprovalIcon from '@mui/icons-material/Approval';
import DatetimeField from 'src/components/DatetimeField';
import { RequestStatus } from 'src/components/RequestStatus';
import { RequestType } from 'src/components/RequestType';

export const RequestIcon = ApprovalIcon;

const ListActions: React.FC = () => <TopToolbar></TopToolbar>;

const filters = [
  <ReferenceInput source="organizationId" reference="organizations" alwaysOn>
    <AutocompleteInput optionValue="id" optionText="name" sx={{ width: 450 }} />
  </ReferenceInput>,
  <SelectInput
    source="requestStatus"
    choices={[
      { id: 'applying', name: 'enum.requestStatus.applying' },
      { id: 'treated', name: 'enum.requestStatus.treated' },
      { id: 'rejected', name: 'enum.requestStatus.rejected' },
    ]}
    alwaysOn
  />,
];

export const RequestList: React.FC = () => (
  <List
    actions={<ListActions />}
    filters={filters}
    perPage={100}
    sort={{ field: 'createdAt', order: 'DESC' }}
    empty={false}
  >
    <Datagrid bulkActionButtons={false}>
      <ShowButton />
      <TextField source="id" sortable={false} />
      <TextField source="organization.name" sortable={false} />
      <FunctionField source="requestStatus" render={(record?: RaRecord) => (record ? <RequestStatus /> : null)} />
      <FunctionField source="requestType" render={(record?: RaRecord) => (record ? <RequestType /> : null)} />
      <DatetimeField source="createdAt" sortable={false} />
      <DatetimeField source="updatedAt" sortable={false} />
    </Datagrid>
  </List>
);
