import * as React from 'react';
import {
  Edit,
  FunctionField,
  Labeled,
  ReferenceArrayInput,
  SaveButton,
  SimpleForm,
  TextField,
  Toolbar,
} from 'react-admin';
import CustomDateTimeInput from 'src/components/DatetimeInput';
import { FormGlobalError } from 'src/components/FormGlobalError';
import DatetimeField from 'src/components/DatetimeField';
import TranslatedField from 'src/components/TranslatedField';
import { DocRecord } from './index';

const ReviewedBookEditToolbar: React.FC = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

export const DocEdit: React.FC = () => {
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm shouldUnregister={true} toolbar={<ReviewedBookEditToolbar />}>
        <FormGlobalError />

        <Labeled>
          <TextField source="id" />
        </Labeled>
        <Labeled>
          <TextField source="type" />
        </Labeled>
        <Labeled>
          <TextField source="title" />
        </Labeled>
        <Labeled>
          <FunctionField
            source="authors"
            render={(record?: { authors: string[] }) => {
              return record?.authors.join('、');
            }}
          />
        </Labeled>
        <Labeled>
          <TextField source="publisher" />
        </Labeled>
        <ReferenceArrayInput source="packIds" reference="packs" />
        <Labeled>
          <FunctionField
            source="planIds"
            render={(record?: DocRecord) => {
              if (record?.planIds?.length) {
                return record.planIds.join('、');
              }
              return '';
            }}
          />
        </Labeled>
        <CustomDateTimeInput
          source="availableFrom"
          helperText="掲載期間を指定しない場合、現時点から閲覧可能を意味します"
        />
        <CustomDateTimeInput
          source="availableUntil"
          helperText="掲載期間を指定しない場合、無期限に閲覧可能を意味します"
        />
        <Labeled>
          <TranslatedField source="purchaseType" prefix="enum.purchaseType" />
        </Labeled>
        <Labeled>
          <DatetimeField source="reservableFrom" />
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};
